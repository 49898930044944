<template>
  <div>
    <section>
      <div class="grid uppercase" v-if="$screen.lg">
        <div
          v-for="item in headings"
          :key="item.idx"
          class="align-center d-col py-15 lightBg text-center"
        >
          <s-icon height="21" color="grey">{{ item.icon }}</s-icon>
          <div class="opacity-60 font-12 pt-10 hide_on_mobile">
            {{ item.title }}
          </div>
        </div>
      </div>
      <hr class="hide" />
    </section>
    <section class="pt-10 table"  v-if="getVisibleLength">
      <div v-for="item in filterSearch" :key="item.id" class="row">
        <div v-if="!$screen.lg">
          <div
            v-for="heading in headings"
            :key="heading.idx"
            :title="heading.title"
          >
            <div v-if="!item.isDeleted" class="grid uppercase pb-1">
              <div class="align-center d-col headingIcon lightBg center">
                <s-icon height="21" color="grey">{{ heading.icon }}</s-icon>
              </div>
            </div>
          </div>
        </div>

        <div class="grid grow" v-if="!item.isDeleted">
          <div class="item">
            {{ item.invoiceNumber }}
          </div>
          <div class="item">
            {{ item.items[0].name }}
          </div>
          <div class="item">
            {{ format(item.createdAt) }}
          </div>

          <div class="item">{{ formatCurrency(item.priceTotal) }}</div>
          <div class="d-flex">
            <button
              @click="view(item)"
              :title="'View invoice'"
              class="item grow mr-1 darkBg center pointer hover"
            >
              <s-icon color="grey">eye-outline</s-icon>
            </button>
          </div>
        </div>
      </div>
    </section>
     <section v-if="!getVisibleLength" class="pa-40 shade4 center">
      <span class="weight-300">No results to display</span>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
export default {
  props: ["search"],
  data() {
    return {
      filtedItems: [],
      pickerID: -1,
      selected: {},
    };
  },
  methods: {
    formatCurrency(x) {
      x = Number(x).toFixed(2);
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return "$ " + parts.join(".");
    },
    getName(id) {
      return this.products.find((el) => el.id == id).name;
    },
    sentense(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    format(val) {
      return dayjs(val).format("DD MMM YY");
    },
    view(val) {
      this.$store.commit("invoice/invoice", val);
      this.$router.push("/invoices/view");
    },
    activate(item) {
      // this.$store.commit("licenses/license", item);
      // this.$router.push("/licenses/invoice/" + item.id);
    },
  },
  computed: {
    getVisibleLength() {
      let data = this.filterSearch;
      if (!Array.isArray(data)) return;
      return data.filter((val) => val.isDeleted !== true).length;
    },
    filterSearch() {
      return this.invoices.filter((val) => {
        if (!val.invoiceNumber) val.invoiceNumber = "";
        if (!val.items[0].name) val.items[0].name = "";
        let term = this.search.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
        let invoiceNumber =
          val.invoiceNumber.toLowerCase().search(term.toLowerCase()) !== -1;
        let name =
          val.items[0].name.toLowerCase().search(term.toLowerCase()) !== -1;
        return invoiceNumber || name;
      });
    },
    ...mapState("user", ["tip"]),
    ...mapState("invoice", ["invoices"]),
    ...mapState("products", ["products"]),
    headings() {
      return [
        { icon: "numeric-0-box-multiple-outline", title: "Invoice No." },
        { icon: "receipt-outline", title: "License name" },
        { icon: "archive-clock-outline", title: "Added on" },
        { icon: "cash", title: "Total Price (USD)" },
        {
          icon: "cursor-default-click-outline",
          title: this.$t("attendees.actions"),
        },
      ];
    },
  },
  watch: {
    search(val) {},
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.table {
  max-height: calc(100vh - 430px);

  overflow-y: auto;
  height: 100%;
  padding: 10px 0px;
  box-sizing: border-box;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr;
  grid-column-gap: 2px;
}

.item {
  display: flex;
  align-items: center;
  color: rgb(180, 180, 180);
  border-bottom: 1px solid #42515e;
  min-height: 36px;
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0.35);
  padding: 8px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hover:hover {
  background-color: rgba(0, 0, 0, 0.45);
}

.box {
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  background-color: #151b23;
  margin-left: 2px;
}

.colorBox {
  min-width: 20px;
  width: 20px;
  background-color: #151b23;
  margin-right: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.rotate {
  transform: translate(4px, 8px) rotate(45deg);
  opacity: 0;
}

.rotate:hover {
  opacity: 1;
}

.darkBg {
  background-color: rgba(0, 0, 0, 0.35);
}

.lightBg {
  background-color: rgba(0, 0, 0, 0.15);
}

.headingIcon {
  height: 40px;
  width: 40px;
  aspect-ratio: 1/1;
}

button:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

@media only screen and (max-width: 991px) {
  .grid {
    display: flex;
    flex-direction: column;
  }

  .table {
    display: flex;
    flex-direction: column;
    gap: 25px;
    max-height: calc(100vh - 334px - var(--footer-height));
  }

  .hide {
    display: none;
  }

  .item {
    min-height: 41px;
  }
}

@media only screen and (max-width: 576px) {
  .grid {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 80px;
  }

  .table {
    max-height: calc(100vh - 333px);
  }
}
</style>